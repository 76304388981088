import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Events from "./pages/Events";
import Join from "./pages/Join";
import Donate from "./pages/Donate";
import Newsletter from "./pages/Newsletter";

function App() {
  return (
    <div className="App">
      <Router>
        {/* Sticky top nav using the MYD color scheme */}
        <header className="sticky top-0 z-50 bg-mydTeal">
          <div className="max-w-7xl mx-auto px-4 py-3 flex items-center justify-between">
            {/* Logo / Title */}
            <div className="flex items-center space-x-3">
              <Link to="/">
                <img
                  src="/myd_logo.jpg"
                  alt="Maine Young Democrats Logo"
                  className="h-10 w-auto"
                />
              </Link>
              <Link to="/" className="text-xl font-bold tracking-wide text-mydCream">
                Maine Young Democrats
              </Link>
            </div>

            {/* Nav links */}
            <nav className="hidden md:flex items-center space-x-6">
              <Link
                to="/events"
                className="text-mydCream hover:text-white transition-colors font-semibold"
              >
                Events
              </Link>
              <Link
                to="/newsletter"
                className="text-mydCream hover:text-white transition-colors font-semibold"
              >
                Newsletter
              </Link>
              <Link
                to="/about"
                className="text-mydCream hover:text-white transition-colors font-semibold"
              >
                About
              </Link>
              <Link
                to="/join"
                className="text-mydCream hover:text-white transition-colors font-semibold"
              >
                Join
              </Link>
            </nav>

            {/* Donate button */}
            <Link
              to="https://secure.actblue.com/donate/maineyoungdems"
              className="bg-mydCream text-mydTeal px-4 py-2 rounded font-semibold hover:opacity-90 transition"
            >
              Donate
            </Link>
          </div>
        </header>

        {/* Main content area grows to push footer down if content is short */}
        <main className="flex-grow">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/events" element={<Events />} />
            <Route path="/newsletter" element={<Newsletter />} />
            <Route path="/about" element={<About />} />
            <Route path="/join" element={<Join />} />
            <Route path="/donate" element={<Donate />} />
          </Routes>
        </main>

        {/* Footer pinned at bottom via mt-auto in the flex container */}
        <footer className="mt-auto bg-mydTeal">
          <div className="max-w-7xl mx-auto px-4 py-8 text-center">
            <p className="text-mydCream text-sm uppercase tracking-wide font-medium">
              &copy; {new Date().getFullYear()} Maine Young Democrats
            </p>
            <p className="text-mydCream text-xs mt-1">
              All rights reserved.
            </p>
          </div>
        </footer>
      </Router>
    </div>
  );
}

export default App;
