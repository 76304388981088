import React, { useEffect, useState } from "react";

function Events() {
  const [events, setEvents] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    fetch("http://localhost:5143/api/events")
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch events");
        }
        return res.json();
      })
      .then((data) => {
        setEvents(data);
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }, []);

  return (
    <div className="fade-up p-8 max-w-6xl mx-auto min-h-[50vh]">
      <h2 className="text-3xl font-extrabold text-mydTeal mb-4">Events</h2>
      <p className="text-gray-600 mb-8">
        Join us for rallies, workshops, and community gatherings:
      </p>

      {errorMessage && <p className="text-red-600 mb-4">{errorMessage}</p>}

      {events.length === 0 ? (
        <p className="text-gray-700">No events available at the moment.</p>
      ) : (
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {events.map((event) => (
            <div
              key={event._id}
              className="bg-white rounded shadow p-6 hover:shadow-lg transition"
            >
              <h3 className="text-xl font-semibold text-mydTeal mb-2">
                {event.title}
              </h3>
              <p className="text-gray-600 mb-1">
                <strong>Date:</strong> {event.date}
              </p>
              <p className="text-gray-600 mb-2">
                <strong>Location:</strong> {event.location}
              </p>
              {event.description && (
                <p className="text-gray-700">{event.description}</p>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Events;
