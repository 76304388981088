import React from "react";

function Donate() {
  // Demo donation page—replace with real payment integration if needed
  return (
    <div className="fade-up min-h-[50vh] bg-white py-16 px-4">
      <div className="max-w-4xl mx-auto text-center">
        <h1 className="text-4xl font-extrabold text-mydTeal mb-6">
          Support the Maine Young Democrats
        </h1>
        <p className="text-gray-700 text-lg leading-relaxed mb-8">
          Your donation helps us organize events, empower young voters,
          and drive progressive change in Maine. Thank you for your support!
        </p>
        <button
          className="bg-mydTeal text-mydCream py-3 px-6 rounded font-bold hover:opacity-90 transition"
          onClick={() =>
            alert("Coming soon!")
          }
        >
          Donate Now
        </button>
      </div>
    </div>
  );
}

export default Donate;
