import React from "react";

function Newsletter() {
  return (
    <div className="fade-up bg-gray-50 min-h-[50vh] py-16 px-4">
      <div className="max-w-5xl mx-auto">
        <h2 className="text-4xl font-extrabold text-mydTeal mb-6">
          Newsletter
        </h2>
        <p className="text-lg text-gray-700 leading-relaxed mb-6">
          This page is coming soon. If you would like to subscribe to our newsletter please email <a href="mailto:youngdemsmaine@gmail.com" className="text-mydTeal transition-colors font-semibold">youngdemsmaine@gmail.com</a>. Thanks for your interest!
        </p>
      </div>
    </div>
  );
}

export default Newsletter;
