import React from "react";

function About() {
  return (
    <div className="fade-up bg-gray-50 min-h-[50vh] py-16 px-4">
      <div className="max-w-5xl mx-auto">
        <h2 className="text-4xl font-extrabold text-mydTeal mb-6">
          About the Maine Young Democrats
        </h2>
        <p className="text-lg text-gray-700 leading-relaxed mb-6">
          We’re a coalition of dedicated young Mainers united by a shared
          belief in a better future. Through workshops, voter outreach,
          and policy advocacy, we empower young people to engage in the
          democratic process at every level.
        </p>
        <p className="text-lg text-gray-700 leading-relaxed">
          From local communities to the state house, the Maine Young Democrats
          are shaping a more equitable and inclusive Maine—one voice at a time.
        </p>
      </div>
    </div>
  );
}

export default About;
