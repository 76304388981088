import React, { useState } from "react";

function Join() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatusMessage("");

    try {
      const res = await fetch("http://localhost:5143/api/volunteers", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ name, email, phone })
      });

      if (!res.ok) {
        throw new Error("Failed to sign up");
      }

      await res.json();
      setStatusMessage("Thank you for volunteering with MYD!");
      setName("");
      setEmail("");
      setPhone("");
    } catch (error) {
      setStatusMessage(error.message);
    }
  };

  return (
    <div className="fade-up bg-gray-50 py-16 px-4 min-h-[50vh]">
      <div className="max-w-lg mx-auto">
        <h2 className="text-3xl font-extrabold text-mydTeal mb-4">
          Become a member
        </h2>
        <p className="text-gray-700 mb-6">
          Sign up below to join the Maine Young Democrats and become a part of the next generation of Democrats in Maine.
        </p>

        {statusMessage && (
          <p className="mb-4 text-green-700 font-semibold">{statusMessage}</p>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-gray-700 font-medium mb-1">
              Name
            </label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-mydTeal"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          <div>
            <label className="block text-gray-700 font-medium mb-1">
              Email
            </label>
            <input
              type="email"
              className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-mydTeal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div>
            <label className="block text-gray-700 font-medium mb-1">
              Phone (Optional)
            </label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-mydTeal"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>

          <button
            type="submit"
            className="bg-mydTeal text-mydCream font-semibold py-2 px-4 rounded hover:opacity-90 transition"
          >
            Sign Up
          </button>
        </form>
      </div>
    </div>
  );
}

export default Join;
