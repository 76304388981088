import React from "react";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="fade-up">
      {/* Hero Section */}
      <div
        className="relative w-full h-[50vh] md:h-[70vh] bg-cover bg-center flex items-center justify-center"
        style={{ backgroundImage: "url('/hero.jpg')" }}
      >
        {/* Fallback teal overlay if hero.jpg is missing */}
        <div className="absolute inset-0 bg-mydTeal bg-opacity-90"></div>

        {/* Hero Content */}
        <div className="relative z-10 p-4 text-center text-mydCream max-w-2xl">
          <h1 className="text-4xl md:text-5xl font-extrabold mb-4">
            Maine Young Democrats
          </h1>
          <p className="text-lg md:text-xl mb-6 font-bold">
            Resistance. Accountability. Humanity. Community. Progress.
          </p>
          <div className="space-x-2">
            <Link
              to="https://secure.actblue.com/donate/maineyoungdems"
              className="bg-mydCream text-mydTeal font-semibold py-2 px-4 rounded hover:opacity-90 transition"
            >
              Donate
            </Link>
            <Link
              to="/join"
              className="border border-mydCream text-mydCream font-semibold py-2 px-4 rounded hover:bg-mydCream hover:text-mydTeal transition"
            >
              Become a member
            </Link>
          </div>
        </div>
      </div>

      {/* Info Section */}
      <div className="py-16 bg-gray-50">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <h2 className="text-2xl md:text-3xl font-bold text-mydTeal mb-6">
            Building Tomorrow’s Leaders
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed max-w-3xl mx-auto mb-8">
            Our mission is to foster political engagement among youth, broaden
            access to leadership roles, and advocate for policies that benefit
            the next generation in Maine.
          </p>
          <Link
            to="/about"
            className="bg-mydTeal text-mydCream px-6 py-3 rounded font-semibold hover:opacity-90 transition-colors"
          >
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
